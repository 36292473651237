import { NATIONAL_CODE } from '../constants/globalKeys'

/**
 * utility function to provide a placeholder while data is loading
 * @param {string} value the value we want to display when available
 * @param {string} placeholder the string that is displayed when value is null/undefined
 * @param {boolean} underlined indicates whether an underlined html should be return or just a string
 * @returns {JSX} if the value is present and underlined is true, returns an HTML <u> element containing the value
 * @returns {string} if value is not present or underlined is false, returns a string (value or placeholder)
 */
function valueOrPlaceholder(
  value,
  placeholder = '____________',
  underlined = true
) {
  if (!!value) {
    if (value === 'NaN') {
      return placeholder
    }
    if (!underlined) {
      return value
    }
    return <u>{value}</u>
  }
  return placeholder
}

function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0
}
/**
 * utility function to get params as an object
 * @param {URLSearchParams} searchParams
 * @param {Array.<string>} keptParms the params to include in the returned object. If empty, all are kept
 * @returns {Object} the params and values and key/value pairs
 */
function getParamsObj(searchParams, keptParams = []) {
  const params = {}

  for (const [key, value] of searchParams) {
    if (key === 'region_code') {
      params[key.toLocaleLowerCase()] = value
    } else {
      params[key.toLocaleLowerCase()] = value.toLowerCase()
    }
  }

  // if keptParams is passed, remove everything not on the list
  if (keptParams.length > 0) {
    for (const key in params) {
      if (!keptParams.includes(key)) {
        delete params[key]
      }
    }
  }

  return params
}

/**
 * emits a custom event for Google Tag Manager (OAW-500)
 * @param {*} payload Same as expected var of same name in handleUpdateTalentPool
 */
function emitUpdateTalentPoolEvent(payload, region, job) {
  const dataObj = {
    event: 'update-talent-pool',
    ...payload,
    job: job,
    skills: JSON.stringify(payload.skills),
    region: region
  }
  for (let i in payload.skills) {
    dataObj[
      `skill.${i}`
    ] = `${payload.skills[i].skill}.${payload.skills[i].average_level}`
  }
  console.debug('emitUpdateTalentPoolEvent', dataObj)
  window.dataLayer.push(dataObj)
}

/**
 *
 * @param {Object} node node object from @nivo/sankey ResponsiveSankey
 * @param {*} region the currently selected region
 */
function emitChangeJobComparisonEvent(node, region) {
  let sankeyClick = { sankey_click_type: 'unknown' }
  if (node.source.node_type.toLowerCase() === 'origin') {
    sankeyClick = {
      sankey_click_type: 'sourcing job',
      job: node.target.label,
      sourcing_destination_job: node.source.label
    }
  } else if (node.target.node_type.toLowerCase() === 'destination') {
    sankeyClick = {
      sankey_click_type: 'destination job',
      job: node.source.label,
      sourcing_destination_job: node.target.label
    }
  }
  const defaults = {
    event: 'change_job_comparison',
    region: region.region_name_long
  }
  const obj = { ...defaults, ...sankeyClick }
  console.debug(obj)
  window.dataLayer.push(obj)
}

/**
 *
 * @param {Object} geoName county or PUMA name
 * @param {*} region the currently selected region
 */
function emitGeoSelectEvent(geoName, region) {
  const obj = {
    event: 'geo-select',
    region: region,
    geo_name: geoName
  }
  console.debug(obj)
  window.dataLayer.push(obj)
}

/**
 * Builds an absolute URL to the specified path including current search params.
 * @param {string} anchorId Element ID to anchor to
 * @returns
 */
function buildParamHashLink(path, anchorId) {
  // Construct a URL based on the current
  let url = new URL(window.location)

  // Update the URL to point to specifided path and hash
  url.pathname = path

  if (anchorId) {
    url.hash = anchorId
  }

  // Create a new param object with persistent params
  const urlSearchParams = url.searchParams
  const newParams = {}
  const persistentParamKeys = [
    'region',
    'job_title',
    'geo_type_id',
    'region_code'
  ]

  persistentParamKeys.forEach((key) => {
    if (urlSearchParams.get(key)) {
      newParams[key] = urlSearchParams.get(key)
    }
  })

  url.search = new URLSearchParams(Object.entries(newParams))

  // Build a local path, including search & hash
  const newUrl = [url.pathname, url.search, url.hash].join('')

  return newUrl
}

/**
 *
 * @param {Object} updatedParamsObj param:value object that will overwrite what already exists
 * @param {Array} unchangedParams array of params to keep. (Tho they may get overwritten by updatedSearchParams)
 * @param {URLSearchParams} searchParams
 * @returns new param:value object that can be passed to update searchParams
 */
function createSearchParam(updatedParamsObj, unchangedParams, searchParams) {
  const searchParamsObj = {}
  for (const entry of searchParams.entries()) {
    const [param, value] = entry
    const isUnchangedParams = unchangedParams.includes(param)
    if (isUnchangedParams) {
      searchParamsObj[param] = value
    }
  }
  return { ...searchParamsObj, ...updatedParamsObj }
}

function regionNameFormatting(regionName, regionCode, geoTypeId) {
  let regionString

  const notCountyName = [
    '22055',
    '22005',
    '22017',
    '22033',
    '22071',
    '22073',
    '22103',
    '22109',
    '2020',
    '11001'
  ]

  if (regionCode === NATIONAL_CODE) {
    regionString = 'the United States'
  } else {
    switch (geoTypeId) {
      case 3:
        regionString = regionName.toLowerCase().includes('county')
          ? regionName
          : notCountyName.includes(regionCode)
          ? `${regionName}`
          : `the County of ${regionName}`
        break

      case 2:
        // Kentucky, Pennsylvania, Massachusetts, Virginia
        const commonwealthStates = [21, 25, 42, 51]

        regionString = commonwealthStates.includes(Number(regionCode))
          ? `the Commonwealth of ${regionName}`
          : `the State of ${regionName}`
        break

      case 1:
        if (regionCode !== NATIONAL_CODE) {
          regionString = `${regionName}`
        }
        break

      default:
        regionString = ''
        break
    }
  }

  return regionString
}

const getParentState = (region, regions) => {
  const parentState =
    region?.statefip && Number(region?.statefip) !== Number(region?.region_code)
      ? regions?.find(
          (item) => Number(region?.statefip) === Number(item?.region_code)
        )
      : region

  return parentState
}

export {
  emitUpdateTalentPoolEvent,
  emitChangeJobComparisonEvent,
  emitGeoSelectEvent,
  valueOrPlaceholder,
  getParamsObj,
  buildParamHashLink,
  createSearchParam,
  isObjectEmpty,
  regionNameFormatting,
  getParentState
}
