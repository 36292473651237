import React, { useState, useEffect } from 'react'
import { useQueryApi } from '../../hooks/useQueryApi'
import styles from './RegionAutocomplete.module.css'
import { useMsaRegionSelection } from '../../hooks/useMsaRegionSelection'
import { Box, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { getParentState } from 'src/util/util'
import { GtmSearchBarFilter, GtmRegionChange } from 'src/util/gtm_utils'
import { useNavigationHistories } from 'src/providers/NavHistoriesContext'

const api_url = process.env.REACT_APP_API_URL

const RegionAutocomplete = () => {
  const { data, isLoading } = useQueryApi({
    endpoint: `${api_url}/region`,
    enable: true
  })

  const { setNavHistoriesValue } = useNavigationHistories()

  const { handleMsaRegionSelection, msaSelectedRegion } =
    useMsaRegionSelection()

  const [inputValue, setInputValue] = useState('')
  const [filteredData, setFilteredData] = useState({
    states: [],
    metros: [],
    counties: []
  })
  const [showDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    if (data) {
      filterData(inputValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, inputValue])

  const filterByGeoType = (geoType, searchTerm) => {
    return data
      .filter(
        (item) =>
          item.geo_type_id === geoType &&
          item.region_name.toLowerCase().startsWith(searchTerm.toLowerCase())
      )
      .sort((a, b) => a.region_name.localeCompare(b.region_name))
      .map((item) => ({
        ...item,
        parentState: item.geo_type_id !== 2 ? getParentState(item, data).region_name : null
      }))
  }

  const filterData = (searchTerm) => {
    if (!data) return

    const filteredStates = filterByGeoType(2, searchTerm)
    const filteredMetros = filterByGeoType(1, searchTerm)
    const filteredCounties = filterByGeoType(3, searchTerm)

    setFilteredData({
      states: filteredStates,
      metros: filteredMetros,
      counties: filteredCounties
    })
    setShowDropdown(searchTerm !== '')

    setFilteredData({
      states: filteredStates,
      metros: filteredMetros,
      counties: filteredCounties
    })

    setShowDropdown(searchTerm !== '')
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setInputValue(value)

    clearTimeout(window.searchDebounce)
    window.searchDebounce = setTimeout(() => {
      filterData(value)
    }, 1200)
  }

  const handleSelectOption = (selectedOption) => {
    if (selectedOption) {
      handleMsaRegionSelection(selectedOption)
      setNavHistoriesValue([getParentState(selectedOption, data), selectedOption])
    }
    setInputValue('')
    setShowDropdown(false)
    GtmSearchBarFilter(msaSelectedRegion, selectedOption)
    GtmRegionChange(msaSelectedRegion, selectedOption, 'geo search')
  }

  const handleHideDropdown = () => {
    setTimeout(() => setShowDropdown(false), 100)
  }

  const allGroupsEmpty = ['states', 'metros', 'counties'].every(
    (group) => filteredData[group].length === 0
  )

  const renderDropdown = () => (
    <div className={styles.searchDropdown}>
      {!allGroupsEmpty ? (
        ['states', 'metros', 'counties'].map((group) => (
          <div key={group}>
            {filteredData[group].length > 0 ? (
              <div className={styles.searchGroup}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingX: 1,
                    paddingY: 1.5
                  }}
                >
                  <Typography
                    variant='body2'
                    sx={{
                      color: 'rgb(117, 117, 117)',
                      fontFamily: 'DM Sans',
                      fontWeight: 700
                    }}
                  >
                    {group.charAt(0).toUpperCase() + group.slice(1)}{' '}
                  </Typography>

                  <span className={styles.resultCount}>
                    {filteredData[group].length}
                  </span>
                </Box>

                <ul>
                  {filteredData[group].map((item) => (
                    <li
                      key={item.id}
                      onClick={() => handleSelectOption(item)}
                      style={{ border: 'none' }}
                    >
                      {item.region_name}
                      {item.parentState ? ` - ${item.parentState}` : ''}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        ))
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingX: 1,
            paddingY: 1.5
          }}
        >
          <Typography
            variant='body'
            sx={{
              color: 'rgb(117, 117, 117)',
              fontFamily: 'DM Sans'
            }}
          >
            No results found
          </Typography>
        </Box>
      )}
    </div>
  )

  return (
    <div className={styles.searchComponent}>
      <div className={styles.inputWithIcons}>
        <SearchIcon className={styles.searchIcon} />
        <input
          className={styles.searchInput}
          type='text'
          placeholder='Find states, metros, or counties…'
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setShowDropdown(true)}
          onBlur={() => setTimeout(handleHideDropdown, 300)}
        />
        {!showDropdown ? (
          <ArrowDropDownIcon
            className={styles.arrowIcon}
            onClick={() => setShowDropdown(true)}
          />
        ) : (
          <ArrowDropUpIcon
            className={styles.arrowIcon}
            onClick={() => setShowDropdown(false)}
          />
        )}
      </div>
      {isLoading && <div>Loading...</div>}
      {showDropdown && renderDropdown()}
    </div>
  )
}

export default RegionAutocomplete
